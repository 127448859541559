'use client';

import { Progress } from './progress';

interface IStepsProps {
  totalSteps: number;
  activeStep: number;
}

const Steps = ({ activeStep, totalSteps }: IStepsProps) => (
  <div className='flex w-full justify-between gap-3'>
    {Array.from({ length: totalSteps }, (_item, index) => (
      <Progress key={index} size={'sm'} value={index <= activeStep - 1 ? 100 : 0} />
    ))}
  </div>
);

Steps.defaultProps = {
  activeStep: 0,
  totalSteps: 3,
};

export { Steps };
